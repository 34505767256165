var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "monitorViode" }, [
    _vm._m(0),
    _c("div", { staticClass: "chkTitle" }, [
      _c(
        "div",
        {
          staticClass: "hoverHand",
          on: {
            click: function ($event) {
              return _vm.chkVideoType(1)
            },
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show1,
                expression: "show1",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/1.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.show1,
                expression: "!show1",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/1-1.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "hoverHand",
          on: {
            click: function ($event) {
              return _vm.chkVideoType(2)
            },
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show2,
                expression: "show2",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/2.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.show2,
                expression: "!show2",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/2-2.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "hoverHand",
          on: {
            click: function ($event) {
              return _vm.chkVideoType(3)
            },
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show3,
                expression: "show3",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/3-3.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.show3,
                expression: "!show3",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/3.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "hoverHand",
          on: {
            click: function ($event) {
              return _vm.chkVideoType(4)
            },
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show4,
                expression: "show4",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/4.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.show4,
                expression: "!show4",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/4-4.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "hoverHand",
          on: {
            click: function ($event) {
              return _vm.chkVideoType(5)
            },
          },
        },
        [
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show5,
                expression: "show5",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/5.png"),
              alt: "",
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.show5,
                expression: "!show5",
              },
            ],
            attrs: {
              src: require("../../../assets/visualization/video/5-5.png"),
              alt: "",
            },
          }),
        ]
      ),
      _c("div", { staticClass: "hoverHand", on: { click: _vm.emptyClick } }, [
        _c("img", {
          staticClass: "XSXXXX",
          attrs: {
            src: require("../../../assets/visualization/video/gb.png"),
            alt: "",
          },
        }),
      ]),
    ]),
    _c("div", { staticClass: "videoBox" }, [
      _c(
        "div",
        {
          staticClass: "left",
          style: { "flex-grow": 1, width: "230px" },
          on: { click: _vm.OptionCardClose },
        },
        [
          _c(
            "div",
            { staticClass: "left-Topinput" },
            [
              _c("el-input", {
                attrs: { placeholder: "搜索", "suffix-icon": "el-icon-search" },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "left-el-tree tree-container" },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree tree",
                attrs: {
                  data: _vm.data,
                  props: _vm.defaultProps,
                  "highlight-current": true,
                  "node-key": "id",
                  "collapse-icon": _vm.getCollapseIcon,
                  "expand-icon": _vm.getExpandIcon,
                  "filter-node-method": _vm.filterNode,
                  "default-expanded-keys": [1],
                  "current-node-key": _vm.currentNodeKey,
                },
                on: { "node-click": _vm.handleNodeClick },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ node, data }) {
                      return _c(
                        "div",
                        { staticClass: "scopeSlotTerr" },
                        [
                          _vm.getImageSrc(node) != ""
                            ? _c("img", {
                                staticStyle: { width: "1vw" },
                                attrs: { src: _vm.getImageSrc(node), alt: "" },
                              })
                            : _vm._e(),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "light",
                                content: "播放全部",
                                placement: "right-start",
                              },
                            },
                            [
                              data.levelNumber == "2"
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "levelClass",
                                      staticStyle: { "margin-left": "10px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.wholeFunction(data)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-video-play custom-icon",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "10px" } },
                            [_vm._v(_vm._s(node.label))]
                          ),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "right", style: { width: _vm.rightWidth + "px" } },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.vidoType,
                  expression: "vidoType",
                },
              ],
              staticClass: "style1",
            },
            _vm._l(_vm.iframeList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  style: _vm.vidoDivStyle,
                  attrs: { id: "vido_div" + index },
                },
                [
                  _c("div", { staticClass: "vido_div_title" }, [
                    _c("div", { staticClass: "vido_div_title-left" }, [
                      _c("span"),
                      _vm._v(
                        "\n              " +
                          _vm._s(item.title) +
                          "\n            "
                      ),
                    ]),
                    _c("div", { staticClass: "vido_div_title-right" }, [
                      _c("img", {
                        staticClass: "XSXXXX",
                        attrs: {
                          src: require("../../../assets/visualization/video/fd.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.imagesClick(item)
                          },
                        },
                      }),
                      _c("img", {
                        staticClass: "XSXXXX",
                        attrs: {
                          src: require("../../../assets/visualization/video/gb.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.videoDElete(item, index)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "vido_div_sp",
                    attrs: { id: item.projeckID },
                  }),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.vidoType,
                  expression: "!vidoType",
                },
              ],
              staticClass: "style2",
            },
            [
              _c(
                "div",
                {
                  staticClass: "style2-top",
                  staticStyle: { height: "66.6666%" },
                },
                [
                  _c(
                    "div",
                    {
                      class: { actionClass: _vm.actionNum1 == 0 },
                      style: {
                        border: "1px solid #009CFF",
                        "background-color": "#003273",
                        padding: "0px 10px",
                        width: "66.6666%",
                        height: "100%",
                      },
                    },
                    [
                      _c("div", { staticClass: "vido_div_title" }, [
                        _c("div", { staticClass: "vido_div_title-left" }, [
                          _c("span"),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.iframeList1[0].title) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "vido_div_title-right" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/fd.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imagesClick(_vm.iframeList1[0])
                              },
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/gb.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.videoDEleteClick(
                                  _vm.iframeList1[0],
                                  0
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "vido_div_sp",
                        attrs: { id: _vm.iframeList1[0].projeckID },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "style2-top-rifht",
                      staticStyle: { width: "33.3333%", height: "100%" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "top-rifht_div1",
                          class: { actionClass: _vm.actionNum1 == 1 },
                          style: {
                            border: "1px solid #009CFF",
                            "background-color": "#003273",
                            padding: "0px 10px",
                            height: "50%",
                          },
                        },
                        [
                          _c("div", { staticClass: "vido_div_title" }, [
                            _c("div", { staticClass: "vido_div_title-left" }, [
                              _c("span"),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.iframeList1[1].title) +
                                  "\n                "
                              ),
                            ]),
                            _c("div", { staticClass: "vido_div_title-right" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../assets/visualization/video/fd.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.imagesClick(_vm.iframeList1[1])
                                  },
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("../../../assets/visualization/video/gb.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.videoDEleteClick(
                                      _vm.iframeList1[1],
                                      1
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", {
                            staticClass: "vido_div_sp",
                            attrs: { id: _vm.iframeList1[1].projeckID },
                          }),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "top-rifht_div2",
                          class: { actionClass: _vm.actionNum1 == 2 },
                          style: {
                            border: "1px solid #009CFF",
                            "background-color": "#003273",
                            padding: "0px 10px",
                            width: "100%",
                            height: "50%",
                          },
                        },
                        [
                          _c("div", { staticClass: "vido_div_title" }, [
                            _c("div", { staticClass: "vido_div_title-left" }, [
                              _c("span"),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.iframeList1[2].title) +
                                  "\n                "
                              ),
                            ]),
                            _c("div", { staticClass: "vido_div_title-right" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../../../assets/visualization/video/fd.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.imagesClick(_vm.iframeList1[2])
                                  },
                                },
                              }),
                              _c("img", {
                                attrs: {
                                  src: require("../../../assets/visualization/video/gb.png"),
                                  alt: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.videoDEleteClick(
                                      _vm.iframeList1[2],
                                      2
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c("div", {
                            staticClass: "vido_div_sp",
                            attrs: { id: _vm.iframeList1[2].projeckID },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "style2-bottom",
                  staticStyle: { height: "33.3%" },
                },
                [
                  _c(
                    "div",
                    {
                      class: { actionClass: _vm.actionNum1 == 3 },
                      style: {
                        border: "1px solid #009CFF",
                        "background-color": "#003273",
                        padding: "0px 10px",
                        width: "33.3333%",
                        height: "100%",
                      },
                    },
                    [
                      _c("div", { staticClass: "vido_div_title" }, [
                        _c("div", { staticClass: "vido_div_title-left" }, [
                          _c("span"),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.iframeList1[3].title) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "vido_div_title-right" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/fd.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imagesClick(_vm.iframeList1[3])
                              },
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/gb.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.videoDEleteClick(
                                  _vm.iframeList1[3],
                                  3
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "vido_div_sp",
                        attrs: { id: _vm.iframeList1[3].projeckID },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: { actionClass: _vm.actionNum1 == 4 },
                      style: {
                        border: "1px solid #009CFF",
                        "background-color": "#003273",
                        padding: "0px 10px",
                        width: "33.3333%",
                        height: "100%",
                      },
                    },
                    [
                      _c("div", { staticClass: "vido_div_title" }, [
                        _c("div", { staticClass: "vido_div_title-left" }, [
                          _c("span"),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.iframeList1[4].title) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "vido_div_title-right" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/fd.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imagesClick(_vm.iframeList1[4])
                              },
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/gb.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.videoDEleteClick(
                                  _vm.iframeList1[4],
                                  4
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "vido_div_sp",
                        attrs: { id: _vm.iframeList1[4].projeckID },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: { actionClass: _vm.actionNum1 == 5 },
                      style: {
                        border: "1px solid #009CFF",
                        "background-color": "#003273",
                        padding: "0px 10px",
                        width: "33.3333%",
                        height: "100%",
                      },
                    },
                    [
                      _c("div", { staticClass: "vido_div_title" }, [
                        _c("div", { staticClass: "vido_div_title-left" }, [
                          _c("span"),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.iframeList1[5].title) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "vido_div_title-right" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/fd.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imagesClick(_vm.iframeList1[5])
                              },
                            },
                          }),
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/gb.png"),
                              alt: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.videoDEleteClick(
                                  _vm.iframeList1[5],
                                  5
                                )
                              },
                            },
                          }),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "vido_div_sp",
                        attrs: { id: _vm.iframeList1[5].projeckID },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.dialogType,
            expression: "dialogType",
          },
        ],
        staticClass: "dialog",
      },
      [
        _c("div", { staticClass: "dialog-main" }, [
          _c("div", { staticClass: "dialog-title" }, [
            _c("div"),
            _c("div", [_vm._v(_vm._s(_vm.dialogTitle))]),
            _c("div", [
              _c("img", {
                staticClass: "hoverHand",
                attrs: {
                  src: require("../../../assets/visualization/video/close.png"),
                  alt: "",
                },
                on: { click: _vm.closeDialog },
              }),
            ]),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "dialog-iframe",
              attrs: { "element-loading-background": "rgba(0, 0, 0, 0.3)" },
            },
            [
              _c("div", { staticClass: "iframe_left", attrs: { id: "FaJK" } }),
              _c("div", { staticClass: "iframe_right" }, [
                _vm._m(1),
                _c("div", { staticClass: "PTZ" }, [_vm._v("PTZ")]),
                _c("div", { staticClass: "CZP" }, [
                  _c("div", { staticClass: "CZP_box" }, [
                    _c("div", { staticClass: "box_li" }, [
                      _c("div"),
                      _c(
                        "div",
                        {
                          staticClass: "XSXXXX",
                          on: {
                            mousedown: function ($event) {
                              return _vm.handleMouseDown(0)
                            },
                            mouseup: function ($event) {
                              return _vm.handleMouseUp(0)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/JT.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c("div"),
                    ]),
                    _c("div", { staticClass: "box_li2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "zuoXZ XSXXXX",
                          on: {
                            mousedown: function ($event) {
                              return _vm.handleMouseDown(2)
                            },
                            mouseup: function ($event) {
                              return _vm.handleMouseUp(2)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/JT.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _vm._m(2),
                      _c(
                        "div",
                        {
                          staticClass: "youXZ XSXXXX",
                          on: {
                            mousedown: function ($event) {
                              return _vm.handleMouseDown(3)
                            },
                            mouseup: function ($event) {
                              return _vm.handleMouseUp(3)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/JT.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "box_li" }, [
                      _c("div"),
                      _c(
                        "div",
                        {
                          staticClass: "fanZ XSXXXX",
                          on: {
                            mousedown: function ($event) {
                              return _vm.handleMouseDown(1)
                            },
                            mouseup: function ($event) {
                              return _vm.handleMouseUp(1)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/visualization/video/JT.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c("div"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "iframe_ui" }, [
                  _c("div", { staticClass: "ui_li" }, [
                    _c(
                      "div",
                      {
                        staticClass: "li-left XSXXXX",
                        on: {
                          mousedown: function ($event) {
                            return _vm.handleMouseDown(9)
                          },
                          mouseup: function ($event) {
                            return _vm.handleMouseUp(9)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../../assets/visualization/video/JIAN.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "li-centent" }, [_vm._v("变倍")]),
                    _c(
                      "div",
                      {
                        staticClass: "li-right XSXXXX",
                        on: {
                          mousedown: function ($event) {
                            return _vm.handleMouseDown(8)
                          },
                          mouseup: function ($event) {
                            return _vm.handleMouseUp(8)
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../../assets/visualization/video/JIA.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]),
                  _vm._m(3),
                  _vm._m(4),
                ]),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "monitorViodeHeader", attrs: { id: "Title" } },
      [
        _c("div", { staticClass: "logo" }),
        _c("div", {
          staticClass: "title-msg",
          staticStyle: { "text-align": "right" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "right_top" }, [
      _c("img", {
        staticClass: "XSXXXX lvjing",
        attrs: {
          src: require("../../../assets/visualization/video/AN1.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "XSXXXX lvjing",
        attrs: {
          src: require("../../../assets/visualization/video/AN2.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "XSXXXX lvjing",
        attrs: {
          src: require("../../../assets/visualization/video/AN3.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "XSXXXX lvjing",
        attrs: {
          src: require("../../../assets/visualization/video/AN4.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "XSXXXX lvjing",
        attrs: {
          src: require("../../../assets/visualization/video/SYTP.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "XSXXXX" }, [
      _c("img", {
        staticClass: "czanImg",
        attrs: {
          src: require("../../../assets/visualization/video/CZAN.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ui_li lvjing" }, [
      _c("div", { staticClass: "li-left XSXXXX" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/visualization/video/JIAN.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "li-centent" }, [_vm._v("光圈")]),
      _c("div", { staticClass: "li-right" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/visualization/video/JIA.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ui_li lvjing" }, [
      _c("div", { staticClass: "li-left XSXXXX" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/visualization/video/JIAN.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "li-centent" }, [_vm._v("聚焦")]),
      _c("div", { staticClass: "li-right XSXXXX" }, [
        _c("img", {
          attrs: {
            src: require("../../../assets/visualization/video/JIA.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }