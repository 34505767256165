
<template>
  <div class="monitorViode">
    <!-- logo - header -->
    <div class="monitorViodeHeader" style="" id="Title">
      <div class="logo"></div>
      <div class="title-msg" style="text-align: right"></div>
    </div>
    <!-- 选项 -->
    <div class="chkTitle">
      <div class="hoverHand" @click="chkVideoType(1)">
        <img
          src="../../../assets/visualization/video/1.png"
          v-show="show1"
          alt=""
        />
        <img
          src="../../../assets/visualization/video/1-1.png"
          v-show="!show1"
          alt=""
        />
      </div>
      <div class="hoverHand" @click="chkVideoType(2)">
        <img
          src="../../../assets/visualization/video/2.png"
          v-show="show2"
          alt=""
        />
        <img
          src="../../../assets/visualization/video/2-2.png"
          v-show="!show2"
          alt=""
        />
      </div>
      <div class="hoverHand" @click="chkVideoType(3)">
        <img
          src="../../../assets/visualization/video/3-3.png"
          v-show="show3"
          alt=""
        />
        <img
          src="../../../assets/visualization/video/3.png"
          v-show="!show3"
          alt=""
        />
      </div>
      <div class="hoverHand" @click="chkVideoType(4)">
        <img
          src="../../../assets/visualization/video/4.png"
          v-show="show4"
          alt=""
        />
        <img
          src="../../../assets/visualization/video/4-4.png"
          v-show="!show4"
          alt=""
        />
      </div>
      <div class="hoverHand" @click="chkVideoType(5)">
        <img
          src="../../../assets/visualization/video/5.png"
          v-show="show5"
          alt=""
        />
        <img
          src="../../../assets/visualization/video/5-5.png"
          v-show="!show5"
          alt=""
        />
      </div>
      <div class="hoverHand" @click="emptyClick">
        <img
          class="XSXXXX"
          src="../../../assets/visualization/video/gb.png"
          alt=""
        />
      </div>
    </div>
    <div class="videoBox">
      <!--  -->
      <div
        class="left"
        :style="{ 'flex-grow': 1, width: '230px' }"
        @click="OptionCardClose"
      >
        <div class="left-Topinput">
          <el-input
            placeholder="搜索"
            suffix-icon="el-icon-search"
            v-model="filterText"
          ></el-input>
        </div>
        <div class="left-el-tree tree-container">
          <el-tree
            class="filter-tree tree"
            ref="tree"
            :data="data"
            :props="defaultProps"
            :highlight-current="true"
            node-key="id"
            :collapse-icon="getCollapseIcon"
            :expand-icon="getExpandIcon"
            :filter-node-method="filterNode"
            :default-expanded-keys="[1]"
            :current-node-key="currentNodeKey"
            @node-click="handleNodeClick"
          >
            <div slot-scope="{ node, data }" class="scopeSlotTerr">
              <img
                v-if="getImageSrc(node) != ''"
                v-bind:src="getImageSrc(node)"
                style="width: 1vw"
                alt=""
              />
              <el-tooltip
                class="item"
                effect="light"
                content="播放全部"
                placement="right-start"
              >
                <span
                  class="levelClass"
                  v-if="data.levelNumber == '2'"
                  @click="wholeFunction(data)"
                  style="margin-left: 10px"
                >
                  <i class="el-icon-video-play custom-icon"></i>
                </span>
              </el-tooltip>
              <span style="margin-left: 10px">{{ node.label }}</span>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="right" :style="{ width: rightWidth + 'px' }">
        <div class="style1" v-show="vidoType">
          <div
            :style="vidoDivStyle"
            :id="'vido_div' + index"
            v-for="(item, index) in iframeList"
            :key="index"
          >
            <div class="vido_div_title">
              <div class="vido_div_title-left">
                <span></span>
                {{ item.title }}
              </div>
              <div class="vido_div_title-right">
                <img
                  class="XSXXXX"
                  src="../../../assets/visualization/video/fd.png"
                  alt=""
                  @click="imagesClick(item)"
                />
                <img
                  class="XSXXXX"
                  src="../../../assets/visualization/video/gb.png"
                  alt=""
                  @click="videoDElete(item, index)"
                />
              </div>
            </div>
            <div class="vido_div_sp" :id="item.projeckID"></div>
            <!-- <div class="vido_div_sp" id="JessibucaProjeckID"></div> -->
          </div>
        </div>
        <div class="style2" v-show="!vidoType">
          <div class="style2-top" style="height: 66.6666%">
            <div
              :style="{
                border: '1px solid #009CFF',
                'background-color': '#003273',
                padding: '0px 10px',
                width: '66.6666%',
                height: '100%',
              }"
              :class="{ actionClass: actionNum1 == 0 }"
            >
              <div class="vido_div_title">
                <div class="vido_div_title-left">
                  <span></span>
                  {{ iframeList1[0].title }}
                </div>
                <div class="vido_div_title-right">
                  <img
                    src="../../../assets/visualization/video/fd.png"
                    alt=""
                    @click="imagesClick(iframeList1[0])"
                  />
                  <img
                    src="../../../assets/visualization/video/gb.png"
                    alt=""
                    @click="videoDEleteClick(iframeList1[0], 0)"
                  />
                </div>
              </div>
              <div class="vido_div_sp" :id="iframeList1[0].projeckID"></div>
            </div>
            <div class="style2-top-rifht" style="width: 33.3333%; height: 100%">
              <div
                :style="{
                  border: '1px solid #009CFF',
                  'background-color': '#003273',
                  padding: '0px 10px',
                  height: '50%',
                }"
                class="top-rifht_div1"
                :class="{ actionClass: actionNum1 == 1 }"
              >
                <div class="vido_div_title">
                  <div class="vido_div_title-left">
                    <span></span>
                    {{ iframeList1[1].title }}
                  </div>
                  <div class="vido_div_title-right">
                    <img
                      src="../../../assets/visualization/video/fd.png"
                      alt=""
                      @click="imagesClick(iframeList1[1])"
                    />
                    <img
                      src="../../../assets/visualization/video/gb.png"
                      alt=""
                      @click="videoDEleteClick(iframeList1[1], 1)"
                    />
                  </div>
                </div>
                <div class="vido_div_sp" :id="iframeList1[1].projeckID"></div>
              </div>
              <div
                :style="{
                  border: '1px solid #009CFF',
                  'background-color': '#003273',
                  padding: '0px 10px',
                  width: '100%',
                  height: '50%',
                }"
                class="top-rifht_div2"
                :class="{ actionClass: actionNum1 == 2 }"
              >
                <div class="vido_div_title">
                  <div class="vido_div_title-left">
                    <span></span>
                    {{ iframeList1[2].title }}
                  </div>
                  <div class="vido_div_title-right">
                    <img
                      src="../../../assets/visualization/video/fd.png"
                      alt=""
                      @click="imagesClick(iframeList1[2])"
                    />
                    <img
                      src="../../../assets/visualization/video/gb.png"
                      alt=""
                      @click="videoDEleteClick(iframeList1[2], 2)"
                    />
                  </div>
                </div>
                <div class="vido_div_sp" :id="iframeList1[2].projeckID"></div>
              </div>
            </div>
          </div>
          <div class="style2-bottom" style="height: 33.3%">
            <div
              :style="{
                border: '1px solid #009CFF',
                'background-color': '#003273',
                padding: '0px 10px',
                width: '33.3333%',
                height: '100%',
              }"
              :class="{ actionClass: actionNum1 == 3 }"
            >
              <div class="vido_div_title">
                <div class="vido_div_title-left">
                  <span></span>
                  {{ iframeList1[3].title }}
                </div>
                <div class="vido_div_title-right">
                  <img
                    src="../../../assets/visualization/video/fd.png"
                    alt=""
                    @click="imagesClick(iframeList1[3])"
                  />
                  <img
                    src="../../../assets/visualization/video/gb.png"
                    alt=""
                    @click="videoDEleteClick(iframeList1[3], 3)"
                  />
                </div>
              </div>
              <div class="vido_div_sp" :id="iframeList1[3].projeckID"></div>
            </div>
            <div
              :style="{
                border: '1px solid #009CFF',
                'background-color': '#003273',
                padding: '0px 10px',
                width: '33.3333%',
                height: '100%',
              }"
              :class="{ actionClass: actionNum1 == 4 }"
            >
              <div class="vido_div_title">
                <div class="vido_div_title-left">
                  <span></span>
                  {{ iframeList1[4].title }}
                </div>
                <div class="vido_div_title-right">
                  <img
                    src="../../../assets/visualization/video/fd.png"
                    alt=""
                    @click="imagesClick(iframeList1[4])"
                  />
                  <img
                    src="../../../assets/visualization/video/gb.png"
                    alt=""
                    @click="videoDEleteClick(iframeList1[4], 4)"
                  />
                </div>
              </div>
              <div class="vido_div_sp" :id="iframeList1[4].projeckID"></div>
            </div>
            <div
              :style="{
                border: '1px solid #009CFF',
                'background-color': '#003273',
                padding: '0px 10px',
                width: '33.3333%',
                height: '100%',
              }"
              :class="{ actionClass: actionNum1 == 5 }"
            >
              <div class="vido_div_title">
                <div class="vido_div_title-left">
                  <span></span>
                  {{ iframeList1[5].title }}
                </div>
                <div class="vido_div_title-right">
                  <img
                    src="../../../assets/visualization/video/fd.png"
                    alt=""
                    @click="imagesClick(iframeList1[5])"
                  />
                  <img
                    src="../../../assets/visualization/video/gb.png"
                    alt=""
                    @click="videoDEleteClick(iframeList1[5], 5)"
                  />
                </div>
              </div>
              <div class="vido_div_sp" :id="iframeList1[5].projeckID"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog" v-show="dialogType">
      <div class="dialog-main">
        <div class="dialog-title">
          <div></div>
          <div>{{ dialogTitle }}</div>
          <div>
            <img
              src="../../../assets/visualization/video/close.png"
              alt=""
              @click="closeDialog"
              class="hoverHand"
            />
          </div>
        </div>
        <div
          class="dialog-iframe"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0.3)"
        >
          <div class="iframe_left" id="FaJK"></div>
          <div class="iframe_right">
            <div class="right_top">
              <img
                class="XSXXXX lvjing"
                src="../../../assets/visualization/video/AN1.png"
                alt=""
              />
                <!-- @click="zTfunction" -->
              <img
                class="XSXXXX lvjing"
                src="../../../assets/visualization/video/AN2.png"
                alt=""
              />
              <img
                class="XSXXXX lvjing"
                src="../../../assets/visualization/video/AN3.png"
                alt=""
              />
              <img
                class="XSXXXX lvjing"
                src="../../../assets/visualization/video/AN4.png"
                alt=""
              />
              <img
                class="XSXXXX lvjing"
                src="../../../assets/visualization/video/SYTP.png"
                alt=""
              />
            </div>
            <div class="PTZ">PTZ</div>
            <div class="CZP">
              <div class="CZP_box">
                <div class="box_li">
                  <div></div>
                  <div
                    class="XSXXXX"
                    @mousedown="handleMouseDown(0)"
                    @mouseup="handleMouseUp(0)"
                  >
                    <img
                      src="../../../assets/visualization/video/JT.png"
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
                <div class="box_li2">
                  <div
                    class="zuoXZ XSXXXX"
                    @mousedown="handleMouseDown(2)"
                    @mouseup="handleMouseUp(2)"
                  >
                    <img
                      src="../../../assets/visualization/video/JT.png"
                      alt=""
                    />
                  </div>
                  <div class="XSXXXX">
                    <img
                      class="czanImg"
                      src="../../../assets/visualization/video/CZAN.png"
                      alt=""
                    />
                  </div>
                  <div
                    class="youXZ XSXXXX"
                    @mousedown="handleMouseDown(3)"
                    @mouseup="handleMouseUp(3)"
                  >
                    <img
                      src="../../../assets/visualization/video/JT.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="box_li">
                  <div></div>
                  <div
                    class="fanZ XSXXXX"
                    @mousedown="handleMouseDown(1)"
                    @mouseup="handleMouseUp(1)"
                  >
                    <img
                      src="../../../assets/visualization/video/JT.png"
                      alt=""
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="iframe_ui">
              <div class="ui_li">
                <div
                  class="li-left XSXXXX"
                  @mousedown="handleMouseDown(9)"
                  @mouseup="handleMouseUp(9)"
                >
                  <img
                    src="../../../assets/visualization/video/JIAN.png"
                    alt=""
                  />
                </div>
                <div class="li-centent">变倍</div>
                <div
                  class="li-right XSXXXX"
                  @mousedown="handleMouseDown(8)"
                  @mouseup="handleMouseUp(8)"
                >
                  <img
                    src="../../../assets/visualization/video/JIA.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="ui_li lvjing">
                <div class="li-left XSXXXX">
                  <img
                    src="../../../assets/visualization/video/JIAN.png"
                    alt=""
                  />
                </div>
                <div class="li-centent">光圈</div>
                <div class="li-right">
                  <img
                    src="../../../assets/visualization/video/JIA.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="ui_li lvjing">
                <div class="li-left XSXXXX">
                  <img
                    src="../../../assets/visualization/video/JIAN.png"
                    alt=""
                  />
                </div>
                <div class="li-centent">聚焦</div>
                <div class="li-right XSXXXX">
                  <img
                    src="../../../assets/visualization/video/JIA.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      optionCardShow: false,
      filterText: "",
      data: [],
      defaultProps: {
        children: "items",
        label: "name",
      },
      currentNodeKey: "",
      optionCardX: "",
      optionCardY: "",
      rightWidth: 0,
      show1: false,
      show2: true,
      show3: true,
      show4: true,
      show5: true,
      show6: true,
      show7: true,
      // 视频播放器样式
      styleClientHeight: 0,
      styleClientWidth: 0,
      styleClient: "1",
      styleWidth: "",
      styleHeight: "",
      vidoDivStyle: {
        width: "100%",
        height: "100%",
      },
      filterText: "",
      data: [
        // {
        //   name: "中材国际",
        //   label: "中材国际",
        //   id: 1,
        //   items: [
        //     {
        //       name: "尼日利亚",
        //       label: "尼日利亚",
        //       id: 11,
        //       levelNumber: 2,
        //       items: [
        //         {
        //           name: "尼日利亚1-1",
        //           label: "尼日利亚1-1",
        //           id: 111,
        //           // url: "http://vjs.zencdn.net/v/oceans.mp4",
        //           url: "https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8",
        //           valid: 1,
        //         },
        //       ],
        //     },
        //     {
        //       name: "印度尼西亚",
        //       label: "印度尼西亚",
        //       id: 121,
        //       levelNumber: 2,
        //       items: [
        //         {
        //           name: "印度尼西亚1-1",
        //           label: "印度尼西亚1-1",
        //           id: 1311,
        //           // url: "https://media.w3.org/2010/05/sintel/trailer.mp4",
        //           url: "http://playertest.longtailvideo.com/adaptive/bipbop/gear4/prog_index.m3u8",
        //           valid: 1,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      defaultProps: {
        children: "items",
        label: "name",
      },
      currentNodeKey: "",
      styleDgtHeight: 0,
      styleClientHeight: 0,
      styleClientWidth: 0,
      iframeList: [
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideo",
          projeckID: "projeckVideoxx",
          primarykeyId: "",
          thoroughfareId: "",
        },
      ],
      iframeList1: [
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideosss0",
          projeckID: "vido_div0xx",
          primarykeyId: "",
          thoroughfareId: "",
        },
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideosss1",
          projeckID: "vido_div1xx",
          primarykeyId: "",
          thoroughfareId: "",
        },
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideoss2",
          projeckID: "vido_div2xx",
          primarykeyId: "",
          thoroughfareId: "",
        },
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideosss3",
          projeckID: "vido_div3xx",
          primarykeyId: "",
          thoroughfareId: "",
        },
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideossss4",
          projeckID: "vido_div4xx",
          primarykeyId: "",
          thoroughfareId: "",
        },
        {
          title: "待连接...",
          iframeUrl: "",
          id: "myVideossss5",
          projeckID: "vido_div5xx",
          primarykeyId: "",
          thoroughfareId: "",
        },
      ],
      actionNum: 0,
      actionNum1: 9999,
      vidoType: true,
      treeClickId: "",
      treeClickCnt: 0,
      loading: false,
      dialogTitle: "",
      bfType: 1,
      dialogType: false,
      iframeListLength: 0,
      token: "",
      primarykeyId: "",
      thoroughfareId: "",
      titleName: "",
      // videoUrl: "https://hse.sinoma.com.cn", // 生产地址
      // videoUrl: "http://127.0.0.1:185", // 开发地址
      // videoUrl: "http://123.60.160.227:80", // 测试地址
      videoUrl: "", // 开发地址
      jessibucaObj: {},
    };
  },
  created() {},
  mounted() {
    let that = this;
    let maxWidth = document.querySelector(".videoBox").clientWidth;
    console.log(document.querySelector(".videoBox").clientWidth);
    that.rightWidth =
      ((document.querySelector(".videoBox").clientHeight + 62) / 9) * 16;
    console.log(that.rightWidth);
    that.styleClientWidth = Number(maxWidth);
    that.loadToken();
    if (location.host == "39.106.228.40") {
      that.videoUrl = process.env.VUE_APP_BASE_VIDEOURL1;
    } else {
      that.videoUrl = process.env.VUE_APP_BASE_VIDEOURL;
    }
  },
  beforeDestroy() {},
  // 计算属性
  computed: {
    getExpandIcon() {
      return (node) => {
        if (node.expanded) {
          return require("../../../assets/visualization/video/SZTZK.png"); // 展开图标
        } else {
          return require("../../../assets/visualization/video/SZTHB.png"); // 折叠图标
        }
      };
    },
    getCollapseIcon() {
      return (node) => {
        if (node.expanded) {
          return require("../../../assets/visualization/video/SZTZK.png"); // 展开图标
        } else {
          return require("../../../assets/visualization/video/SZTHB.png"); // 折叠图标
        }
      };
    },
    getImageSrc() {
      return (node) => {
        if (node.data.items) {
          if (node.data.items.length != 0) {
            if (node.expanded) {
              return require("../../../assets/visualization/video/SZTZK.png"); // 子节点展开的图标
            } else {
              return require("../../../assets/visualization/video/SZTHB.png"); // 子节点折叠的图标
            }
          } else {
            return require("../../../assets/visualization/video/SPKH.png");
          }
        } else if (node.data.valid === 1) {
          return require("../../../assets/visualization/video/SZTJK.png"); // 可监控的图标
        } else {
          return require("../../../assets/visualization/video/JKSX.png"); // 监视摄像机的图标
        }
      };
    },
  },
  methods: {
    // 获取树结构数据源
    loadToken() {
      let that = this;
      let originalString = "admin";
      let encodedString = btoa(originalString);
      // http://8.147.130.99:8080/LoginToService
      let url =
        that.videoUrl +
        "/dmonitor/LoginToService?username=admin&password=" +
        encodedString;
      axios
        .get(url, data)
        .then(function (response) {
          that.token = response.data.token;
          that.loadTreeData();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadTreeData() {
      let that = this;
      let data = {
        // token: that.token,
        // httpflv:1,
      };
      let url =
        that.videoUrl +
        "/dmonitor/org/Channels?token=" +
        that.token +
        "&httpflv=1&ws=1";
      axios
        .get(url, data)
        .then(function (response) {
          console.log("原生接口信息", response);
          const newData = that.treeLoop(response.data.tree);
          that.data = newData;
          // that.data = response.data.tree;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 树 数据层级处理
    treeLoop(arr) {
      if (!arr) arr = [];
      var newArr = JSON.parse(JSON.stringify(arr));
      var lastItem = undefined;
      const _loop = function (data) {
        if (!data) data = [];
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          if (item.type == 1) {
            lastItem = item;
            if (!item.items) item.items = [];
            _loop(item.items);
          }
          if (item.type == 2) {
            var children = item.items;
            var lastItemChildren = lastItem.items || [];
            children.forEach((child) => {
              lastItemChildren.push(child);
            });
            lastItem.items = lastItemChildren;
            lastItem.levelNumber = 2;
          }
        }
      };
      _loop(newArr);
      const _loop1 = function (data) {
        if (!data) data = [];
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          if (item.items) {
            item.items = item.items.filter((itm) => itm.type != 2);
            _loop1(item.items);
          }
        }
      };
      _loop1(newArr);
      // for (let i = 0; i < arr.length; i++) {
      //   let item = arr[i];
      //   let newItem = { ...item };
      //   var type = item.type;
      //   console.log(type);
      //   if (type == 1 || type === 3) {
      //     newItem.items = this.treeLoop(item.items);
      //     result.push(newItem);
      //   }
      //   if (type == 2) {
      //     let children = [];
      //     const transfer = (list) => {
      //       if (!list) list = [];
      //       list.forEach((obj) => {
      //         if (obj.url) {
      //           children.push(obj);
      //         } else {
      //           transfer(obj.items);
      //         }
      //       });
      //     };
      //     transfer(item.items);
      //     newItem.items = children;
      //     newItem.levelNumber = 2;
      //     result.push(newItem);
      //   }
      // }
      return newArr;
    },
    // 清空页面播放器
    emptyClick() {
      let that = this;
      if (this.vidoType) {
        this.iframeList.forEach((item) => {
          if (item.iframeUrl != "") {
            item.title = "待连接...";
            item.iframeUrl = "";
            // var player = videojs(item.id);
            // player.dispose();
            that.destroyPlayer(item.projeckID);
          }
        });
      } else {
        this.iframeList1.forEach((item, index) => {
          if (item.iframeUrl != "") {
            item.title = "待连接...";
            item.iframeUrl = "";
            // var player = videojs(item.id);
            // player.dispose();
            that.destroyPlayer(item.projeckID);
          }
        });
      }
    },
    // 计算视频播放器的宽度和高度
    chkVideoType(type) {
      this.styleClient = type;
      this.show1 = true;
      this.show2 = true;
      this.show3 = true;
      this.show4 = true;
      this.show5 = true;
      this.show6 = true;
      this.show7 = true;
      this.calculatewidthandheight();
      switch (type) {
        case 1:
          this.show1 = false;
          break;
        case 2:
          this.show2 = false;
          break;
        case 3:
          this.show3 = false;
          break;
        case 4:
          this.show4 = false;
          break;
        case 5:
          this.show5 = false;
          break;
        case 6:
          this.show6 = false;
          break;
        case 7:
          this.show7 = false;
          break;
      }
      if (type != 4) {
        this.vidoType = true;
      }
      if (type == 1) {
        this.dataProcessing(1);
        this.actionNum = 0;
      }
      if (type == 2) {
        this.dataProcessing(4);
        this.actionNum = 0;
      }
      if (type == 3) {
        this.dataProcessing(9);
        this.actionNum = 0;
      }
      if (type == 4) {
        this.vidoType = false;
      }
      if (type == 5) {
        this.dataProcessing(20);
        this.actionNum = 0;
      }
      if (type == 6) {
        this.vidoDivStyle.width = "19%";
        this.vidoDivStyle.height = "19%";

        this.actionNum = 0;
      }
      if (type == 7) {
        this.vidoDivStyle.width = "16%";
        this.vidoDivStyle.height = "16%";

        this.actionNum = 0;
      }
    },
    // 处理数据
    dataProcessing(index) {
      console.log(index);
      let that = this;
      let spList = [];
      if (index < this.iframeList.length) {
        spList = this.iframeList.splice(index);
      }
      if (spList.length > 0) {
        spList.forEach((item) => {
          if (item.iframeUrl) {
            let player = videojs(item.id);
            player.dispose();
            // 创建元素
            var video = document.createElement("video");
            video.id = item.id;
            video.className = "video-js vjs-default-skin vjs-big-play-centered";
            video.setAttribute("v-if", item.iframeUrl);
            video.autoplay = true;
            video.setAttribute("mutedwebkit-playsinline", "true");
            video.setAttribute("playsinline", "true");
            video.style.width = "100%";
            video.style.height = "100%";
            video.type = "video/m3u8";

            // 将新创建的元素添加到 DOM 中
            var el = document.getElementById(item.projeckID);
            el.innerHTML = "";
            el.appendChild(video);
            that.$nextTick(() => {
              player = videojs(item.id, {
                muted: true,
                autoplay: true,
              });
            });
          }
        });
      }
      for (let i = this.iframeList.length; i < index; i++) {
        this.iframeList.push({
          title: "待连接...",
          iframeUrl: "",
          id: "myVideo" + i + "xx",
          projeckID: "projeckVideo" + i + "xxx",
          primarykeyId: "",
          thoroughfareId: "",
        });
      }
      this.videoID = this.iframeList[0].id;
    },
    // 计算视频窗口大小
    calculatewidthandheight() {
      let num = 0;
      let numZX = 0;
      if (this.styleClient == 1) {
        num = Math.round(this.styleClientHeight / 9);
        this.styleWidth = 100;
        this.styleHeight = 100;
      }
      if (this.styleClient == 2) {
        numZX = Math.round(this.styleClientHeight / 2);
        num = numZX / 9;
        this.styleWidth = 50;
        this.styleHeight = 50;
      }
      if (this.styleClient == 3) {
        numZX = Math.round(this.styleClientHeight / 3);
        num = numZX / 9;
        this.styleWidth = 33.3;
        this.styleHeight = 33.3;
      }
      if (this.styleClient == 4) {
      }
      if (this.styleClient == 5) {
        numZX = Math.round(this.styleClientHeight / 4);
        num = numZX / 9;
        this.styleWidth = 20;
        this.styleHeight = 25;
      }
      this.vidoDivStyle.width = this.styleWidth + "%";
      this.vidoDivStyle.height = this.styleHeight + "%";
    },
    // 鼠标滑出自定义右键菜单
    OptionCardClose() {
      this.optionCardShow = false;
    },
    // 树节点搜索过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 点击节点
    handleNodeClick(data, node) {
      console.log("树节点数据", data, node);
      let that = this;
      that.currentNodeKey = data.id;
      that.currentNodeKey = data.id;
      if (data.id != that.treeClickId) {
        that.treeClickCnt = 0;
      }
      that.treeClickId = data.id;
      that.treeClickCnt++;
      if (that.treeClickCnt > 2) return;
      setTimeout(() => {
        if (that.treeClickCnt == 1) {
        } else if (that.treeClickCnt == 2) {
          console.log("双击节点", data);
          that.sameClick(data);
        }
        that.treeClickCnt = 0;
      }, 300);
    },
    // 播放全部视频
    wholeFunction(data) {
      this.optionData = data;
      let numLength = 0;
      let arrList = [];
      this.optionData.items.forEach((item) => {
        if (item.url && item.valid == 1) {
          arrList.push(item);
        }
      });
      if (this.vidoType) {
        if (arrList.lenght < this.iframeList.length) {
          numLength = arrList.lenght;
        } else {
          numLength = this.iframeList.length;
        }
      } else {
        if (arrList.lenght < this.iframeList1.length) {
          numLength = arrList.lenght;
        } else {
          numLength = this.iframeList1.length;
        }
      }
      if (arrList.length > 0) {
        this.emptyClick();

        for (let i = 0; i < numLength; i++) {
          this.sameClick(arrList[i]);
        }
      } else {
        return;
      }
    },
    findName(arrList, url, parentName = null) {
      let that = this;
      for (let item of arrList) {
        if (item.url === url) {
          that.titleName = parentName || "unknown"; // 使用上一级的名称
          return true;
        } else if (item.items) {
          if (that.findName(item.items, url, item.name)) return true; // 传递当前级别的名称作为下一级的父名称
        }
      }
      return false;
    },
    //树状图双击事件
    sameClick(row) {
      let that = this;
      let dz = that.videoUrl + "/liveVideo/";
      if (!row) return;
      //  let terrUrl = row.httpflv.replace(
      //   "http://8.147.130.99:53372/",
      //   dz
      // );
      let terrUrl = dz + row.httpflv;
      // let terrUrl = row.url;
      //let id = "projeckVideoxx";

      let arrList = that.data[0].items;
      this.findName(arrList, row.url);
      let arrIframeUrl = [];
      let arrIframeUrlType = 1;
      let arrIframeUrlIndex = 0;
      let arrIframeUrl1 = [];
      let arrIframeUrlIndex1 = 0;
      if (this.vidoType) {
        let numbType = 1;
        this.iframeList.forEach((item) => {
          if (item.iframeUrl == terrUrl) {
            console.log("监控连接！", item.iframeUrl, terrUrl);
            numbType = 2;
          }
        });
        if (numbType == 2) {
          this.$message({
            message: "监控正在播放！",
            type: "warning",
          });
          return;
        }
        if (row.url && row.valid == 1) {
          let numBxx = this.iframeListLength + 1;
          this.iframeList.forEach((item, index) => {
            if (item.iframeUrl == "") {
              arrIframeUrl.push(index);
              arrIframeUrlType = 2;
            }
          });
          if (numBxx > this.iframeList.length && arrIframeUrlType == 1) {
            return;
          } else {
            if (arrIframeUrl.length != 0) {
              arrIframeUrlIndex = arrIframeUrl[0];
            }
            this.iframeList[arrIframeUrlIndex].iframeUrl = terrUrl;
            this.iframeList[arrIframeUrlIndex].title =
              that.titleName + "-" + row.name;
            this.iframeList[arrIframeUrlIndex].primarykeyId = row.device;
            this.iframeList[arrIframeUrlIndex].thoroughfareId = row.channelNo;
            // let ID = this.iframeList[arrIframeUrlIndex].id;
            let ID = this.iframeList[arrIframeUrlIndex].projeckID;
            this.jessibucaObj[ID] = null;
            this.jessibucaObj[ID] = this.create(ID, terrUrl);
            // const video = document.createElement("video");
            // video.id = ID;
            // video.className = "video-js vjs-default-skin vjs-big-play-centered";
            // video.autoplay = true;
            // video.setAttribute("mutedwebkit-playsinline", true);
            // video.setAttribute("playsinline", true);
            // video.style.width = "100%";
            // video.style.height = "100%";
            // video.style.objectFit = "cover";
            // video.setAttribute("type", "video/m3u8");
            // // 将新创建的元素添加到 DOM 中
            // var el = document.getElementById(
            //   that.iframeList[arrIframeUrlIndex].projeckID
            // );
            // el.innerHTML = "";
            // el.appendChild(video);
            // that.$nextTick(() => {
            //   let existingPlayer = videojs.getPlayer(ID);
            //   let player;
            //   if (existingPlayer) {
            //     existingPlayer.dispose();
            //   }
            //   player = videojs(ID, {
            //     muted: true,
            //     autoplay: true,
            //   });
            //   var newSrc = terrUrl;
            //   player.src({ src: newSrc, type: "application/x-mpegURL" });
            // });
            this.iframeListLength += 1;
          }
        } else {
          this.$message({
            message: "视频未连接！",
            type: "warning",
          });
        }
      } else {
        let numbType = 1;
        this.iframeList1.forEach((item) => {
          if (item.iframeUrl == terrUrl) {
            numbType = 2;
          }
        });
        if (numbType == 2) {
          this.$message({
            message: "监控正在播放！",
            type: "warning",
          });
          return;
        }
        if (row.url && row.valid == 1) {
          this.iframeList1.forEach((item, index) => {
            if (item.iframeUrl == "") {
              arrIframeUrl1.push(index);
              arrIframeUrlType = 2;
            }
          });
          if (arrIframeUrlType == 1) {
            return;
          } else {
            if (arrIframeUrl1.length != 0) {
              arrIframeUrlIndex1 = arrIframeUrl1[0];
            }
            this.iframeList1[arrIframeUrlIndex1].iframeUrl = terrUrl;
            this.iframeList1[arrIframeUrlIndex1].title =
              that.titleName + "-" + row.name;
            this.iframeList1[arrIframeUrlIndex1].primarykeyId = row.device;
            this.iframeList1[arrIframeUrlIndex1].thoroughfareId = row.channelNo;
            let ID1 = this.iframeList1[arrIframeUrlIndex1].projeckID;
            this.jessibucaObj[ID1] = null;
            this.jessibucaObj[ID1] = this.create(ID1, terrUrl);
            // const video = document.createElement("video");
            // video.id = ID1;
            // video.className = "video-js vjs-default-skin vjs-big-play-centered";
            // video.autoplay = true;
            // video.setAttribute("mutedwebkit-playsinline", true);
            // video.setAttribute("playsinline", true);
            // video.style.width = "100%";
            // video.style.height = "100%";
            // video.style.objectFit = "cover";
            // video.setAttribute("type", "video/m3u8");
            // // 将新创建的元素添加到 DOM 中
            // var el = document.getElementById(
            //   that.iframeList1[arrIframeUrlIndex1].projeckID
            // );
            // el.innerHTML = "";
            // el.appendChild(video);
            // that.$nextTick(() => {
            //   let existingPlayer = videojs.getPlayer(ID1);
            //   let player;
            //   if (existingPlayer) {
            //     existingPlayer.dispose();
            //   }
            //   player = videojs(ID1, {
            //     muted: true,
            //     autoplay: true,
            //   });
            //   var newSrc = terrUrl;
            //   player.src({ src: newSrc, type: "application/x-mpegURL" });
            // });
          }
        } else {
          this.$message({
            message: "视频未连接！",
            type: "warning",
          });
        }
      }
    },
    create(id, url) {
      var container = document.getElementById(id);
      let jessibuca = new window.Jessibuca(
        Object.assign({
          container: container,
          autoWasm: true, // 在使用MSE或者Webcodecs 播放H265的时候，是否自动降级到wasm模式。
          background: "", //背景图片
          controlAutoHide: false, //底部控制台是否自动隐藏
          debug: false, //是否开启控制台调试打印
          // decoder: "/jessibuca/decoder.js",
          hasVideo: true, //  是否开启控制台调试打印
          heartTimeout: 5, //设置超时时长, 单位秒播放中途,如果超过设定时长无数据返回,则回调timeout事件
          heartTimeoutReplay: true, //是否开启心跳超时之后自动再播放
          heartTimeoutReplayTimes: 3, //重试次数  heartTimeoutReplay 重试失败之后，不再重新播放视频地址。如果想无限次重试，可以设置为-1
          hiddenAutoPause: false, //是否开启当页面的'visibilityState'变为'hidden'的时候，自动暂停播放。
          hotKey: false, //是否开启键盘快捷键 esc -> 退出全屏；arrowUp -> 声音增加；arrowDown -> 声音减少；
          isFlv: false, //当为true的时候：ws协议不检验是否以.flv为依据，进行协议解析。
          isFullResize: false, //当为true的时候：视频画面做等比缩放后,完全填充canvas区域,画面不被拉伸,没有黑边,但画面显示不全
          isNotMute: false, // 是否开启声音，默认是关闭声音播放的。
          isResize: false, //当为true的时候：视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。 当为false的时候：视频画面完全填充canvas区域,画面会被拉伸。
          keepScreenOn: false, //开启屏幕常亮，在手机浏览器上, canvas标签渲染视频并不会像video标签那样保持屏幕常亮。PC端不会生效，仅手机端生效
          loadingText: "请稍等, 数据连接中......", // 视频加载转圈时的提示文字
          loadingTimeout: 10, //当play()的时候，如果没有数据返回，则回调
          loadingTimeoutReplay: true, ///是否开启loading超时之后自动再播放
          loadingTimeoutReplayTimes: 3, //loadingTimeoutReplay 重试失败之后，不再重新播放视频地址。
          operateBtns: {
            // 配置操作按钮 其中
            fullscreen: false, //全屏按钮
            screenshot: false, //截图按钮
            play: false, //播放暂停按钮
            audio: false, //声音按钮
            record: false, //录制按钮
          },
          recordType: "webm", //默认录制的视频格式
          rotate: 0, //设置旋转角度
          showBandwidth: false, // 显示网速
          supportDblclickFullscreen: false, // 是否支持屏幕的双击事件，触发全屏，取消全屏事件。
          timeout: 10, //设置超时时长, 单位秒在连接成功之前(loading)和播放中途(heart),如果超过设定时长无数据返回,则回调timeout事件
          useMSE:
            location.hostname !== "localhost" && location.protocol !== "https:", //是否开启MediaSource硬解码
          useWCS:
            location.hostname === "localhost" || location.protocol === "https", //是否开启Webcodecs硬解码
          useWebFullScreen: false, //是否使用web全屏(旋转90度)（只会在移动端生效）。
          videoBuffer: 0.2, // 设置最大缓冲时长，单位秒，播放器会自动消除延迟。
          wasmDecodeErrorReplay: true, // 是否开启解码失败重新播放
          wcsUseVideoRender: true, //webcodecs硬解码是否通过video标签渲染
        })
      );
      let arr = "https://flvplayer.js.org/assets/video/weathering-with-you.flv";
      jessibuca.play(url);
      return jessibuca;
    },
    //  放大查看视频监控
    imagesClick(item) {
      this.primarykeyId = item.primarykeyId;
      this.thoroughfareId = item.thoroughfareId;
      let that = this;
      if (item.iframeUrl) {
        let terrUrl = item.iframeUrl;
        this.dialogType = true;
        this.dialogTitle = item.title;
        let ID = "FaJK";
        this.jessibucaObj[ID] = null;
        this.jessibucaObj[ID] = this.create(ID, terrUrl);
        // const video = document.createElement("video");
        // video.id = "iframeVideoXXXXXX";
        // video.className = "video-js vjs-default-skin vjs-big-play-centered";
        // video.autoplay = true;
        // video.setAttribute("mutedwebkit-playsinline", true);
        // video.setAttribute("playsinline", true);
        // video.style.width = "100%";
        // video.style.height = "100%";
        // video.style.objectFit = "cover";
        // video.setAttribute("type", "video/m3u8");

        // // 将新创建的元素添加到 DOM 中
        // var el = document.getElementById("FaJK");
        // el.innerHTML = "";
        // el.appendChild(video);

        // that.$nextTick(() => {
        //   let existingPlayer = videojs.getPlayer("iframeVideoXXXXXX");
        //   let player;
        //   if (existingPlayer) {
        //     existingPlayer.dispose();
        //   }
        //   player = videojs("iframeVideoXXXXXX", {
        //     muted: true,
        //     autoplay: true,
        //   });
        //   var newSrc = terrUrl;
        //   player.src({ src: newSrc, type: "application/x-mpegURL" });
        // });
      } else {
        this.$message({
          message: "视频未连接！",
          type: "warning",
        });
      }
    },
    videoDEleteClick(item, index) {
      if (this.iframeList1[index].iframeUrl == "") return;
      this.iframeList1[index].title = "待连接...";
      this.iframeList1[index].iframeUrl = "";
      let player = videojs(item.id);
      player.dispose();
    },
    videoChange() {
      let that = this;
      if (this.vidoType) {
        this.iframeList1.forEach((item, index) => {
          if (item.iframeUrl) {
            item.title = "待连接...";
            item.iframeUrl = "";
            // let player = videojs(item.id);
            // player.dispose();
             that.destroyPlayer(item.projeckID);
          }
        });
      } else {
        if (this.cesssss == 0) {
          this.cesssss = 1;
        }
      }
    },
    // 清除视频
    videoDElete(item, index) {
      if (this.iframeList[index].iframeUrl == "") return;
      this.iframeList[index].title = "待连接...";
      this.iframeList[index].iframeUrl = "";
      // var player = videojs(item.id);
      // player.dispose();
      // this.jessibucaObj[item.projeckID].destroy()
      this.destroyPlayer(item.projeckID);
    },
    // 销毁视频监控播放器
    destroyPlayer(player) {
      if (this.jessibucaObj[player]) {
        this.jessibucaObj[player]
          .destroy()
          .then(() => {
            console.log("Player has been destroyed");
          })
          .catch((error) => {
            console.error("Error destroying player:", error);
          });
      }
    },
    // 控制球机方向
    handleMouseDown(index) {
      let that = this;
      let data = {};
      let url =
        that.videoUrl +
        "/dmonitor/ptz?deviceid=" +
        that.primarykeyId +
        "&channel=" +
        that.thoroughfareId +
        "&op=" +
        index +
        "&start=1&speed=2";
      axios
        .get(url, data)
        .then(function (response) {
          // console.log("控制球机方向", response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 结束控制球机方向
    handleMouseUp(index) {
      let that = this;
      let data = {};
      let url =
        that.videoUrl +
        "/dmonitor/ptz?deviceid=" +
        that.primarykeyId +
        "&channel=" +
        that.thoroughfareId +
        "&op=" +
        index +
        "&start=0&speed=2";
      axios
        .get(url, data)
        .then(function (response) {
          // console.log("控制球机方向", response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 关闭查看视频监控弹窗
    closeDialog() {
      // let player = videojs("iframeVideoXXXXXX");
      // player.dispose();
      // $(".dialog").fadeOut();
       let ID = "FaJK";
      this.destroyPlayer(ID);
      this.dialogType = false;
      this.bfType = 1;
    },
    zTfunction() {
      let player = videojs("iframeVideoXXXXXX");
      if (this.bfType == 1) {
        player.pause();
        this.bfType = 2;
      } else {
        player.play();
      }
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    vidoType() {
      this.videoChange();
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  width: 100vw;
  height: 100vh;
  background-color: #001752;
  padding: 1vh;
}
body .monitorViode {
  width: 100%;
  height: 100%;
  background-color: #001752;
}
.monitorViodeHeader {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: -1vh;
}
#Title {
  width: 100%;
  height: 8%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background-image: url(../../../assets/visualization/video/spjkBJ.png);
  background-size: 100% 100%;
  padding: 10px 20px 0 20px;
}
#Title div {
  flex: 1;
}
.chkTitle {
  width: 100%;
  height: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 0.5vh; */
}
.chkTitle > div {
  width: 1.5vw;
  height: 1.0708vw;
  margin: 0 0.5vw;
}
.chkTitle > div > img {
  width: 100%;
  height: 100%;
}
.videoBox {
  width: 100%;
  display: flex;
  height: calc(90% - 1.5vh);
  justify-content: space-evenly;
  margin-top: 1vh;
  padding: 0 20px;
}
.left {
  // width: 230px;
  background: url(../../../assets/visualization/video/leftBG.png);
  background-size: 100% 100%;
  height: 100%;
  padding: 1.563vw 0.42vw;
  margin-right: 20px;
}
.left-Topinput {
  width: 100%;
  background-image: url(../../../assets/visualization/video/searchBG.png);
  background-size: 100% 100%;
  padding: 0.26vw 0.3vw;
}
::v-deep .el-input__inner {
  background-color: transparent !important;
  border: none !important;
  color: #0aa5ff !important;
}
::v-deep .el-input__icon {
  color: #0aa5ff !important;
  font-size: 20px !important;
}
.right {
  width: 80vw;
  background: url(../../../assets/visualization/video/rightBG.png);
  background-size: 100% 100%;
  height: 100%;
}

input::placeholder {
  color: #0aa5ff !important;
}
.left-titleName {
  width: 100%;
  margin-top: 37px;
  color: #fff;
  padding: 5px 12px;
  font-size: 18px;
  font-family: "Microsoft YaHei";
}
.left-el-tree {
  width: 100%;
  height: calc(100% - 60px);
  padding: 0 0.417vw;
  font-family: "Microsoft YaHei";
}
::v-deep .el-tree {
  height: 100%;
  width: 100%;
  background-color: transparent !important;
  color: #fff !important;
  overflow: auto;
}
::v-deep .el-tree-node__content {
  height: 30px !important;
}
::v-deep .el-tree-node__content:hover,
.el-upload-list__item:hover {
  background-color: transparent !important;
  background-image: url(../../../assets/visualization/video/searchBG.png);
  background-size: 100% 100%;
}
::v-deep .el-tree .el-tree-node:focus > .el-tree-node__content {
  background-color: transparent !important;
  background-image: url(../../../assets/visualization/video/searchBG.png);
  background-size: 100% 100%;
}
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: transparent !important;
  background-image: url(../../../assets/visualization/video/searchBG.png);
  background-size: 100% 100%;
}

::v-deep .el-tree-node__label {
  font-size: 0.729vw !important;
}

// ::v-deep .tree .el-tree-node {
//   position: relative;
//   padding-left: 0.938vw !important;
// }
::v-deep .el-tree > .el-tree-node {
  display: inline-block;
  min-width: 100%;
  box-sizing: border-box;
}
::v-deep .tree .el-tree-node__children {
  padding-left: 0.938vw;
}

::v-deep .tree .el-tree-node :last-child:before {
  height: 1.979vw;
}

::v-deep .tree .el-tree > .el-tree-node:before {
  border-left: none;
}

::v-deep .tree-container .el-tree > .el-tree-node:after {
  border-top: none;
}
::v-deep.tree .el-tree-node:before {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}
::v-deep .tree .el-tree-node:after {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}
::v-deep .tree .el-tree-node:before {
  border-left: 1px dashed #23bfe2 !important;
  bottom: 0px;
  height: 100%;
  top: -1.304vw;
  width: 1px;
}
::v-deep .tree .el-tree-node:after {
  border-top: 1px dashed #23bfe2;
  height: 1.042vw;
  top: 0.755vw;
  width: 1.25vw;
}
::v-deep .el-tree-node__content {
  padding-left: 0px !important;
}
::v-deep.el-tree-node__label {
  font-size: 0.729vw !important;
}

::v-deep .tree .el-tree-node {
  position: relative;
  padding-left: 0.938vw !important;
}

::v-deep .tree .el-tree-node__children {
  padding-left: 0.938vw;
}

::v-deep .tree .el-tree-node :last-child:before {
  height: 1.979vw;
}

::v-deep .tree .el-tree > .el-tree-node:before {
  border-left: none;
}

::v-deep .tree-container .el-tree > .el-tree-node:after {
  border-top: none;
}

::v-deep .tree .el-tree-node:before {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

::v-deep .tree .el-tree-node:after {
  content: "";
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

::v-deep .tree .el-tree-node:before {
  border-left: 1px dashed #23bfe2 !important;
  bottom: 0px;
  height: 100%;
  top: -1.304vw;
  width: 1px;
}

::v-deep .tree .el-tree-node:after {
  border-top: 1px dashed #23bfe2;
  height: 1.042vw;
  top: 0.755vw;
  width: 1.25vw;
}
::v-deep .el-tree-node__content {
  padding-left: 0px !important;
}

::v-deep .el-tree-node__expand-icon.expanded {
  transform: rotate(0) !important;
}
::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
  padding: 1px !important;
}
::v-deep .scopeSlotTerr {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 4px 5px;
}
::v-deep .el-tree .el-icon-caret-right:before {
  content: "";
}
::v-deep
  .el-tree
  .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  /* background: url(../img/SZTZK.png) no-repeat 0 3px; */
  content: "";
  /* display: block;
  width: 0.833vw;
  height: 1.042vw;
  font-size: 0.833vw;
  background-size: 0.833vw; */
}
::v-deep .tree-container .el-tree .el-tree-node__expand-icon.is-leaf::before {
  content: "";
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  color: #fff !important;
}
.XSXXXX:hover {
  cursor: pointer;
}
.style1 {
  width: 100%;
  height: calc(100% - 0vw);
  display: flex;
  padding: 10px;
  justify-content: space-evenly;
  align-content: space-around;
  flex-wrap: wrap;
}
.style2 {
  width: 100%;
  height: calc(100% - 0vw);
  padding: 10px;
}
.style2-top {
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  flex-wrap: wrap;
}
.style2-top-left {
  border: 1px solid #009cff;
  background-color: #003273;
  padding: 5px 10px;
}

.style2-top-rifht > div {
  width: 100%;
  height: calc(50% - 5px);
  border: 1px solid #009cff;
  background-color: #003273;
}

.style2-bottom {
  width: calc(100%);
  height: 32%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.style2-bottom > div {
  width: calc(33.33333% - 10px);
  height: 100%;
  border: 1px solid #009cff;
  background-color: #003273;
  padding: 5px 10px;
}
.right .style1 > div {
  border: 1px solid #0093c3;
  background-color: #003273;
  padding: 0px 10px;
}
.right .style1 .actionClass {
  border: 2px solid #009cff !important;
}
.actionClass {
  border: 2px solid #009cff !important;
}
.vido_divC {
  width: 100%;
  height: 100%;
}
.vido_div_title {
  width: 100%;
  /* height: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 5px 15px;
  padding-right: 5px;
  font-size: 0.729vw;
}
.vido_div_title .vido_div_title-left > span {
  border: 3px solid #1ebff4;
  border-radius: 50%;
  margin-right: 0.417vw;
  display: inline-block;
}
.vido_div_title-right > img {
  width: 1.198vw;
  height: 1.042vw;
  /* margin-left: 5px; */
}
.videoSpDiv {
  width: 100%;
  height: 100%;
}
.vido_div_sp {
  width: 100%;
  height: calc(100% - 40px);
  background-color: #000;
}
#parent-container {
  width: 100%;
  height: 100%;
}

#my-player {
  position: relative;
  padding-bottom: 56.25%;
}
.scopeSlotTerr:hover .levelClass {
  display: block;
}

.levelClass {
  display: none;
}
.custom-icon {
  font-weight: 600;
  color: #22bcdf;
  font-size: 1.042vw;
}
.dialog {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-main {
  width: 90%;
  height: 90%;
  background: url("../../../assets/visualization/video/dialogBG.png");
  background-size: 100% 100%;
  padding: 10px;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  color: #00e9ff;
  /* color: #43cbfe; */
  font-weight: bold;
  font-size: 1.2vw;
  height: 40px;
  margin-bottom: 25px;
}

.dialog-title img {
  transform: scale(0.8);
}

.dialog-iframe {
  width: 100%;
  height: calc(100% - 65px);
  display: flex;
}
.center-top-msg div:hover {
  cursor: pointer;
}
.iframe_left {
  width: 85%;
  height: 100%;
  padding: 15px;
  background-color: #000;
}
.iframe_right {
  width: 15%;
  height: 100%;
  padding: 15px;
}
.right_top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35%;
}
.right_top img {
  width: 16%;
  /* margin: 0 5px; */
}
.PTZ {
  width: 100%;
  margin-top: 30px;
  color: #fff;
  font-weight: 550;
  font-size: 0.885vw;
  padding-left: 10px;
}
.CZP {
  width: 100%;
  margin-top: 10px;
  padding-left: 30px;
}
.CZP_box {
  width: 8.073vw;
  height: 8.073vw;
  background-image: url(../../../assets/visualization/video/CZP.png);
  background-size: 100% 100%;
  padding: 10px;
}
.box_li {
  width: 100%;
  height: 6%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box_li2 {
  width: 100%;
  height: 86.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.czanImg {
  width: 24px;
  height: 24px;
}

.zuoXZ {
  transform: rotate(270deg);
}
.youXZ {
  transform: rotate(90deg);
}
.fanZ {
  transform: rotate(180deg);
}
.iframe_ui {
  width: 100%;
  padding-left: 2.604vw;
  margin-top: 2.604vw;
}
.ui_li {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.042vw;
}
.li-left {
  width: 1.327vw;
  height: 1.327vw;
}
.li-left img {
  width: 100%;
}
.li-right {
  width: 1.327vw;
  height: 1.327vw;
}
.li-right img {
  width: 100%;
}
.li-centent {
  color: #fff;
  margin: 0 0.781vw;
  font-size: 0.885vw;
  letter-spacing: 0.781vw;
  margin-right: 0;
}
.lvjing {
  filter: grayscale(1);
  cursor: not-allowed !important;
}
</style>
